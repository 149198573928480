<template>
	<v-container class="mt-4 px-5" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Directorio General</span>
	  		    <v-spacer></v-spacer>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
	  		  </v-card-title>
          
	  		  <v-card-text>
	  		  	<v-row justify="end">
	  		  		<v-col cols="12" md="6">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  append-icon="mdi-magnify"
	  		  			  v-model="search"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>
	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="directorio"
							    class="elevation-0"
							    :search="search"
							    :mobile-breakpoint="100"
			            dense
							  >
							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>
							  </v-data-table>
					  	</v-col>
					  </v-row>
					</v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>

  	<!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'


  export default {

    mixins: [ validarErrores, funcionesExcel ],

  	components:{
      Alerta,
      carga,
    },

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      headers: [
        { text: 'Plantel'       , value: 'plantel' },
        { text: 'T. Princial'   , value: 'tel_principal'   },
        { text: 'T. Secundario' , value: 'tel_secundario'  },
        { text: 'Wpp Ventas'    , value: 'wpp_ventas'      },
        { text: 'Wpp Sucursal'  , value: 'wpp_sucursal'    },
      ],
      directorio: [],
      // tabla
			search:'',
    }),

    computed:{
      ...mapGetters('login',['getdatosUsuario','getLogeado', 'getEscuela','getEstatusLlamada','getTareas','getNuevosProspectos']),
      ...mapGetters('tema', ['getTema']),
      ...mapGetters('Carrito',['getCarrito']),
    },

    created () {
    	console.log( 'escuela',this.getEscuela)
      this.initialize()
    },

    methods: {
      initialize () {
      	this.cargar = true
        this.directorio = []
        return this.$http.get('planteles.list.erp').then(response=>{
        	console.log( response.data )

        	if( [18,19].includes( this.getdatosUsuario.idpuesto ) ){
        		this.directorio = response.data.filter( el => el.escuela == this.getEscuela && !el.plantel.match('Entrenam') && !el.plantel.match('CEAA') && !el.plantel.match('XOLA'))
        	}else{
        		this.directorio = response.data.filter( el => !el.plantel.match('Entrenam') && !el.plantel.match('CEAA') && !el.plantel.match('XOLA') )
        	}
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },
    }
  }
</script>


